import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function Termsofuse() {
  return (
    <div className="container">
    <div className="fnt-16">
        <h1 className="my-5 py-5 text-center fw-bold">Terms of Service</h1>
        <div className="mb-4">These Terms of Service govern your use of the website located at <a className="accent-color" href="https://www.dripfunnel.com/">dripfunnel.com</a> and any related services provided by dripfunnel.</div>
        <div className="mb-4">By accessing <a className="accent-color" href="https://www.dripfunnel.com/">dripfunnel.com</a>, you agree to abide by these Terms of Service and to comply with all applicable laws and regulations. If you do not agree with these Terms of Service, you are prohibited from using or accessing this website or using any other services provided by dripfunnel.</div>
        <div className="mb-4">We, dripfunnel, reserve the right to review and amend any of these Terms of Service at our sole discretion. Upon doing so, we will update this page. Any changes to these Terms of Service will take effect immediately from the date of publication.</div>
        <div className="mb-4">These Terms of Service were last updated on 1st November 2021.</div>
        <div className="mb-4 fw-bolder">Limitations of Use</div>
        <div className="mb-4">By using this website, you warrant on behalf of yourself, your users, and other parties you represent that you will not:</div>
        <div>
            <ol>
                <li className="mb-4">modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on this website;</li>
                <li className="mb-4">remove any copyright or other proprietary notations from any materials and software on this website;</li>
                <li className="mb-4">transfer the materials to another person or “mirror” the materials on any other server;</li>
                <li className="mb-4">knowingly or negligently use this website or any of its associated services in a way that abuses or disrupts our networks or any other service dripfunnel provides;</li>
                <li className="mb-4">use this website or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent, or unlawful material;</li>
                <li className="mb-4">use this website or its associated services in violation of any applicable laws or regulations;</li>
                <li className="mb-4">use this website in conjunction with sending unauthorized advertising or spam;</li>
                <li className="mb-4">harvest, collect, or gather user data without the user’s consent; or</li>
                <li className="mb-4">use this website or its associated services in such a way that may infringe the privacy, intellectual property rights, or other rights of third parties.</li>
            </ol>
        </div>
        <div className="fw-bold mb-4">Intellectual Property</div>
        <div className="mb-4">The intellectual property in the materials contained in this website are owned by or licensed to dripfunnel and are protected by applicable copyright and trademark law. We grant our users permission to download one copy of the materials for personal, non-commercial transitory use.</div>
        <div className="mb-4">This constitutes the grant of a license, not a transfer of title. This license shall automatically terminate if you violate any of these restrictions or the Terms of Service, and may be terminated by dripfunnel at any time.</div>
        <div className="fw-bold mb-4">Liability</div>
        <div className="mb-4">Our website and the materials on our website are provided on an 'as is' basis. To the extent permitted by law, dripfunnel makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property, or other violation of rights.</div>
        <div className="mb-4"> In no event shall dripfunnel or its suppliers be liable for any consequential loss suffered or incurred by you or any third party arising from the use or inability to use this website or the materials on this website, even if dripfunnel or an authorized representative has been notified, orally or in writing, of the possibility of such damage.</div>
       <div className="mb-4">In the context of this agreement, “consequential loss” includes any consequential loss, indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under statute, contract, equity, tort (including negligence), indemnity, or otherwise.</div> 
       <div className="mb-4">Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</div>
       <div className="fw-bold mb-4">Accuracy of Materials</div>
       <div className="mb-4">The materials appearing on our website are not comprehensive and are for general information purposes only. dripfunnel does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on this website, or otherwise relating to such materials or on any resources linked to this website.</div>
       <div className="fw-bold mb-4">Links</div>
       <div className="mb-4">Dripfunnel has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement, approval, or control by dripfunnel of the site. Use of any such linked site is at your own risk and we strongly advise you make your own investigations with respect to the suitability of those sites.</div>
       <div className="fw-bold mb-4">Right to Terminate</div>
       <div className="mb-4">We may suspend or terminate your right to use our website and terminate these Terms of Service immediately upon written notice to you for any breach of these Terms of Service.</div>
       <div className="fw-bold mb-4">Severance</div>
       <div className="mb-4">Any term of these Terms of Service which is wholly or partially void or unenforceable is severed to the extent that it is void or unenforceable. The validity of the remainder of these Terms of Service is not affected.</div>
       <div className="fw-bold mb-4">Governing Law</div>
       <div className="mb-4">These Terms of Service are governed by and construed in accordance with the laws of Australia. You irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</div>
       <div className="fw-bold mb-4">Receive Marketing Communications</div>
       <div className="mb-4">
        By accepting our terms and conditions, you give us permission to share promotional emails related to our products, services, and events – plus other information we believe may be beneficial for your use.<br/></div>
        <div className="mb-5">You may unsubscribe from these emails at any time by clicking the “unsubscribe” link at the bottom of each email. Please note that by unsubscribing, you may miss out on important updates and offers from <a className="accent-color"href="https://www.dripfunnel.com/">dripfunnel.com</a></div>
    </div>
</div>
  );
}

export default Termsofuse;
